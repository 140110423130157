@font-face {
  font-family: buttonFont;
  src: url(./assets/fonts/NewTegomin-Regular.ttf);
}

@font-face {
  font-family: textFont;
  src: url(./assets/fonts/Dosis-VariableFont_wght.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6 {
  font-family: 'textFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  color: white;
  margin: 0.4rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #33CCFF;
}

p {
  font-size: 1.3em;
}

h1 {
  font-size: 6em;
}

h2 {
  font-size: 3.5em;
}

h3 {
  font-size: 3em;
}

button {
  font-family: 'buttonFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.emphasisColor {
  color: #33CCFF;
}
