.appContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    z-index: 10;

    padding: 10px;

    width: 97%;
    height: 95%;
    background-color: rgba(0, 0, 0, 0.5);

    border-radius: 10px;
    border: 4px solid #999;
}

.appContainer {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    background-color: black;

    align-items: center;
    justify-content: space-around;
}

.appBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 5;

    opacity: 0.8;
}

.appInnerContentContainer {
    display: flex;
    flex: 1;
    flex-direction: column;

    margin: 12px;

    padding: 12px;

    border-radius: 16px;

    height: 90%;
}