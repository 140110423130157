.navButton {
    margin: 8px 8px;
    font-weight: bold;
    font-size: 1.2em;

    padding: 12px 12px;
    background-color: #2D2D2D;
    color: #33CCFF;
    border-radius: 12px;

    outline: none;
}


.navButton:hover {
    background-color: #3B3B3B;
    color: #FFF;

    cursor: pointer;
}