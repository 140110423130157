.navigationBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 25px 14px;

    margin: 10px;

    border-right: 4px solid #33CCFF;

    min-height: 30%;
}